<template>

    <div v-if="!isLoading" class="row">
        <div class="row" style="text-align: left;">
            <div v-if="showParaTab" class="col-sm-1">
                <input type="radio" name="courseId" id="paraCourse" :checked="canvasCourseId === 261"
                       @change="changeSelectedCourse(261)"/>
                <label for="paraCourse">Para</label>
            </div>
            <div class="col-sm-1" v-if="showSupervisorTab">
                <!--      <div class="col-sm-1">-->
                <input type="radio" name="courseId" id="supervisorCourse" :checked="canvasCourseId === 268"
                       @change="changeSelectedCourse(268)"/>
                <label for="supervisorCourse">Supervisor</label>
            </div>
            <div class="col-sm-9"></div>
        </div>
        <!--    <div class="card" style="margin-left: .35%; width: 97.5%">-->
        <div class="container-fluid mb-2" style="border: 1px solid black; padding: 2%;">
            <!--      <div class="card-body">-->
            <h1 style="color: black;">Reports for {{ user.firstName }} {{ user.lastName }}</h1>
            <h6 style="color: black;">Pretest scores appear, but have no bearing on the final score.</h6>
            <h6 style="color: black; font-style: italic;">
                Questions and Answers for Quizzes or Posttests are updated with Canvas every night at midnight,
                America/Central Time.
                <br/>
                Scores are up to date, but individual questions and answers may be up to 24 hours out of sync with
                Canvas.
                <br/>
                Optional modules are not required to complete the specific certification within Project Para.
            </h6>
            <div v-if="user.enabled && !noGradedValues">
                <b-tooltip target="refresh-button" triggers="hover"
                           title="Refreshing reports can take some time. Click here to update the user's report.">
                    <button id="refresh-button" class="btn-primary button bi-arrow-repeat" type="button"
                            @click="updateCertifications">
                        Refresh Reports
                    </button>
                </b-tooltip>
            </div>
            <div v-else-if="noGradedValues">
                <h6>{{ user.firstName }} {{ user.lastName }} may or may not have started work in
                    {{ canvasCourseId === 261 ? "Project Para" : "Supervisor" }} Course. But they have not completed any
                    assignment that contributes to a certificate.</h6>
            </div>
            <hr style="color: black;"/>
            <div class="row" v-if="!noGradedValues">
                <div class="col-sm " v-for="certification in certifications" :key="certification.idCertification"
                     :id="certification.idCertifcation">
                    <div class="card">
                        <div class="card-header"
                             style="border-bottom: 1px solid black; background-color: rgba(0, 0, 0, 0.03);">
                            <label>{{ certification.title }}</label>
                            <div>
                                <span>{{ certification.gradedModuleItems }} out of {{
                                    certification.gradeableModuleItems
                                    }} of items submitted</span>
                                <br/>
                                <span
                                        v-if="certification.gradedPercent !== null && certification.gradedPercent !== undefined && certification.gradedPercent.toFixed(3) > 0.000">
            Graded score: {{ certification.gradedPercent.toFixed(3) }}%
          </span>
                                <div v-if="certification.completionDate !== 'January 1, 1970'">
                                    <button class="bi-download btn-primary button" :disabled="downloadPdfButtonDisabled"
                                            @click="downloadPdf(`${user.firstName} ${user.lastName}`, certification.title, certification.completionDate)">
                                        Certification
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="accordion card-body" :id="'certAccordion' + certification.idCertification">
                            <div class="accordion-item" v-for="module in certification.modules" :key="module.idModule">
                                <h2 class="accordion-header" :id="module.name + module.idModule">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse"

                                            :data-target="'#module' + module.idModule + '_cert' + certification.idCertification"
                                            aria-expanded="false"
                                            :aria-controls="'module' + module.idModule + '_cert' + certification.idCertification"
                                            style="border-radius: 0">
                                        <div class="col-sm">
                                            <span>
                                                <span style="font-style: italic" v-if="!module.isRequiredForCompletion">
                                                (Optional)
                                                </span> {{ module.name }}</span>

                                        </div>
                                    </button>
                                    <div style="font-size: 45%" v-if="module.gradeableItems > 0">
                                        <i>{{ module.gradedItems }} out of {{ module.gradeableItems }} items submitted&#9;</i>

                                        <i v-if="!module.passed && module.gradedItems > 0" style="color: red;"
                                           class="bi bi-flag-fill"></i>

                                    </div>
                                    <hr/>
                                </h2>
                                <div :id="'module' + module.idModule + '_cert' + certification.idCertification"
                                     class="accordion-collapse collapse"
                                     :aria-labelledby="module.name + module.idModule"
                                     style="border: 1px solid black; margin-bottom: 10px">
                                    <strong v-for="item in module.moduleItems" :key="item.idModuleItem"
                                            style="padding: 5px; color: black; opacity: .9; font-size: 18px; ">
                                        {{ item.title }}
                                        <!--                <span style="color: royalblue; font-weight: bold;" v-if="!item.gradeable">{{-->
                                        <!--                  '\u2605'-->
                                        <!--                }}</span>-->
                                        <div v-if="item.gradeable && !item.title.includes('Activity')">

                                            <div class="row"
                                                 v-if="(item.assignments[0].grade !== null && item.assignments[0].grade !== undefined && item.assignments[0].grade.score !== null)">
                                                <div class="col-sm"><span>Graded Percent:</span></div>
                                                <div class="col-sm"
                                                     v-if="item.assignments[0].grade.score / item.assignments[0].pointsPossible * 100 >= 69"
                                                ><span style="color: darkgreen;">{{
                                                    (item.assignments[0].grade.score / item.assignments[0].pointsPossible * 100).toFixed(3)
                                                    }}%</span></div>
                                                <div class="col-sm" v-else style="color: red;">{{
                                                    (item.assignments[0].grade.score / item.assignments[0].pointsPossible * 100).toFixed(3)
                                                    }}%
                                                </div>
                                                <div
                                                        v-if="(item.title.includes('Posttest') || item.title.includes('Certificate') || item.title.includes('Content Skills')) && (item.assignments[0].grade.score < item.assignments[0].pointsPossible)">
                                                    <button style="margin-top: 8px; margin-bottom: 2px;"
                                                            :disabled="!showRetakeButton(item.assignments[0].grade.score, item.assignments[0].pointsPossible, item.title, item.assignments[0].grade.submissionAfterRetake)"
                                                            @click="retakeButtonClicked(item.assignments[0].idQuiz, item.title)"
                                                            type="button"
                                                            class="btn-primary button">{{
                                                        showRetakeButton(item.assignments[0].grade.score, item.assignments[0].pointsPossible, item.title, item.assignments[0].grade.submissionAfterRetake) ? `Retake` : `In Progress`
                                                        }}
                                                    </button>
                                                </div>
                                                <div
                                                        v-if="item.assignments[0].grade.retakeCount !== undefined
                                                    && item.assignments[0].grade.retakeCount !== null
                                                    && item.assignments[0].grade.retakeCount !== 0">
                                                    <span style="font-size: 14px; font-style: italic;">Retakes: {{
                                                        item.assignments[0].grade.retakeCount
                                                        }}</span><span> | </span>
                                                    <span style="font-size: 14px; font-style: italic;">Retake Sent:
                                                        {{
                                                        item.assignments[0].grade.lastRetakeSubmission !== ""
                                                        && item.assignments[0].grade.lastRetakeSubmission !== null
                                                        && item.assignments[0].grade.lastRetakeSubmission !== undefined
                                                            ? item.assignments[0].grade.lastRetakeSubmission
                                                            : "Cancelled"
                                                        }}</span>
                                                    <p style="font-size: 14px; font-style: italic;">Last Approved By:
                                                        {{ item.assignments[0].grade.lastRetakeCreatedBy }}</p>
                                                </div>
                                                <div class="col"
                                                     v-if="(item.title.includes('Posttest') || item.title.includes('Certificate') || item.title.includes('Content Skills')) && (item.assignments[0].grade.score < item.assignments[0].pointsPossible) && item.showQuizDetails">
                                                    <button class="btn-primary button"
                                                            @click="quizDataButtonClicked(item.assignments[0].idQuiz, item.assignments[0].grade.idGrade, !item.assignments[0].grade.submissionAfterRetake)">
                                                        Detailed
                                                        Quiz Info
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-else-if="!item.title.includes('Pretest')" class="col-sm">
                                                <span style="color: blue;">Not submitted yet</span>
                                            </div>
                                        </div>
                                        <hr/>
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--      </div>-->
        </div>
    </div>
    <div v-else>
        <Loader :loading-value="`reports for ${user.firstName} ${user.lastName}. We apologize for any delay, we are synchronizing data with Canvas.`"/>
    </div>
    <div v-if="showRetakeModal">
        <Teleport to="body">
            <QuizRetakeModal
                    :show="showRetakeModal"
                    :user-name="`${user.firstName} ${user.lastName}`"
                    :quiz-title="quizTitle"
                    :course-id="canvasCourseId"
                    :user-canvas-id="user.canvasId"
                    :quiz-id="retakeQuizId"
                    @close="showRetakeModal = false"
                    @success="retakeSuccess"
            />
        </Teleport>
    </div>
    <div v-if="showQuizDataModal">
        <Teleport to="body">
            <UserQuizDisplay :show-modal="showQuizDataModal" :id-quiz="quizDataModalId" :id-user="userId"
                             @close="showQuizDataModal = false" :id-grade="selectedGradeId"
                             :id-viewing-user="viewingUserId"
                             :active-retake="retakeActiveOnQADisplay"
                             @submittedReview="reviewSubmitted"
            />
        </Teleport>
    </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref, h} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";
import {
	certificationsByParaCall, getCertificationByUserAndCourse, getCertificationByUserId,
	updateCertificationByParaCall,
	updateCertificationByUserCall, updateCertificationsByUserAndCourse
} from "@/Helpers/ApiCalls/ReportAPICalls";
import Loader from "@/components/Loader";
import CertificationPdfGenerator from "@/components/Reporting/Certifications/CertificationPdfGenerator";
import {genPdf} from "@/components/Reporting/Certifications/GenPdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import QuizRetakeModal from "@/components/QuizRetake/QuizRetakeModal.vue";
import {isParaEducatorCollegeStudentOrEarlyChildhood} from "@/RoleCheck";
import CancelQuizRetakeModal from "@/components/QuizRetake/CancelQuizRetakeModal.vue";
import UserQuizDisplay from "@/components/Quizzes/UserQuizDisplay.vue";

export default {
	name: "CertificationsTemplate",
	components: {
		// eslint-disable-next-line vue/no-unused-components
		UserQuizDisplay,
		QuizRetakeModal,
		// eslint-disable-next-line vue/no-unused-components
		CertificationPdfGenerator,
		Loader,
	},
	props: {
		idUser: String,
	},
	setup(props, context) {
		const store = useStore();
		const authToken = computed(() => store.getters['auth/authenticated']);
		const authUser = computed(() => store.getters['auth/user']);
		const user = ref({});
		const certifications = ref([]);
		const isLoading = ref(true);
		const canvasCourseId = ref(261);
		const showParaTab = ref(true);
		const showSupervisorTab = ref(false);
		const downloadPdfButtonDisabled = ref(false);
		const showRetakeModal = ref(false);
		const retakeQuizId = ref(-1);
		const quizTitle = ref("");
		const showQuizDataModal = ref(false);
		const quizDataModalId = ref(-1);
		const selectedGradeId = ref(-1);
		const retakeActiveOnQADisplay = ref(false);

		onBeforeMount(() => {
			if (isParaEducatorCollegeStudentOrEarlyChildhood(authUser.value.position.roleHierarchy) && authUser.value.idUser !== parseInt(atob(props.idUser))) {
				location.replace("/dashboard");
			} else {
				getUser();
			}
		});

		async function getUser() {
			let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(atob(props.idUser));
			await axios.get(get_uri, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				user.value = result.data;
			}).finally(() => {
				switch (user.value.enrollmentType.idEnrollmentType) {
					default:
						break;
					case 2:
						canvasCourseId.value = 268;
						showParaTab.value = false;
						showSupervisorTab.value = true;
						break;
					case 3:
						showSupervisorTab.value = true;
						break;
				}
				if (user.value.statusFlag.statusCode === 'A') {
					updateCertifications();
				} else {
					getCertifications();
				}
			})
		}

		async function getCertifications() {
			isLoading.value = true;
			await axios.get(getCertificationByUserAndCourse(parseInt(atob(props.idUser)), canvasCourseId.value), {
				headers: {
					"Authorization": "Bearer " + authToken.value
				},
			}).then((result) => {
				certifications.value = result.data;
			}).finally(() => {
				isLoading.value = false;
			})
		}

		async function updateCertifications() {
			isLoading.value = true;
			while (certifications.value.length > 0) certifications.value.pop();
			await axios.get(updateCertificationsByUserAndCourse(parseInt(atob(props.idUser)), canvasCourseId.value), {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				certifications.value = result.data;
				isLoading.value = false;
			}).catch((error) => {
				getCertifications();
				isLoading.value = false;
			})
		}

		function changeSelectedCourse(idCourse) {
			canvasCourseId.value = idCourse;
			updateCertifications();
		}

		const noGradedValues = computed(() => {
			let graded_items = 0;
			for (let i = 0; i < certifications.value.length; i++) {
				graded_items += certifications.value[i].gradedModuleItems;
			}
			return graded_items === 0;
		})

		function downloadPdf(userName, certName, date) {
			let htmlString = `<div class="cert-body row" style="background-image: url('/images/background_templates/certificate_bg.png'); background-size: 1140px 790px; width: 1185px; height: 795px;background-repeat: no-repeat;padding: 20px;text-align: center;align-items: center; position: relative;">
      <div>
        <br/><br/>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black;">This is to acknowledge that</span>
        <br><br>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black;"><b>${userName}</b></span><br/><br/>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black;">has satisfactorily completed:</span> <br/><br/>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black; word-wrap: break-word; font-weight: bold;"><i>${certName}.</span></i> <br/><br/>
        <div class="col-sm-4 mt-4" style="margin-left: 65px; position: absolute;">
          <span style="font-size: 25px; font-family: 'DejaVu Sans',serif; color: black;">Completion Date:</span>
          <p ><span style="font-size: 25px; font-family: 'DejaVu Sans',serif; color: black; border-bottom: 1px solid black;">${date}</span></p>
        </div>
      </div>
    </div>`;
			let htmlObj = document.createElement("div");
			htmlObj.innerHTML = htmlString;
			document.body.appendChild(htmlObj)
			const fileName = `${userName.replace(" ", "_")}_${certName.replace(" ", "_")}`
			genPdf(htmlObj, fileName);
			htmlObj.remove();
		}

		function showRetakeButton(score, maxScore, title, subAfterRetake) {
			let title_valid = title.includes("Posttest") || title.includes("Content Skills");
			return score < maxScore && user.value.statusFlag.statusCode === 'A' && title_valid && !isParaEducatorCollegeStudentOrEarlyChildhood(authUser.value.position.roleHierarchy) && subAfterRetake;
		}


		function retakeButtonClicked(idQuiz, title) {
			retakeQuizId.value = idQuiz;
			quizTitle.value = title;
			showRetakeModal.value = true;
		}

		function quizDataButtonClicked(idQuiz, idGrade, noRetake) {
			showQuizDataModal.value = true;
			quizDataModalId.value = idQuiz;
			selectedGradeId.value = idGrade;
			retakeActiveOnQADisplay.value = noRetake;
		}


		function retakeSuccess() {
			Swal.fire({
				title: "SUCCESS",
				text: `${user.value.firstName} ${user.value.lastName} has been given an extra attempt on ${quizTitle.value}`,
				icon: "success"
			}).then(() => {
				updateCertifications();
			})
		}

		function reviewSubmitted() {
			showQuizDataModal.value = false;
			getCertifications();
		}

		const userId = computed(() => {
			return parseInt(atob(props.idUser));
		})

		const viewingUserId = computed(() => {
			return authUser.value.idUser;
		})

		return {
			viewingUserId,
			user,
			certifications,
			isLoading,
			showParaTab,
			showSupervisorTab,
			canvasCourseId,
			noGradedValues,
			downloadPdfButtonDisabled,
			retakeQuizId,
			showRetakeModal,
			quizTitle,
			showQuizDataModal,
			quizDataModalId,
			userId,
			selectedGradeId,
			retakeActiveOnQADisplay,
			updateCertifications,
			changeSelectedCourse,
			downloadPdf,
			showRetakeButton,
			retakeButtonClicked,
			retakeSuccess,
			quizDataButtonClicked,
			reviewSubmitted,
		}
	}
}
</script>

<style scoped>
span {
    font-weight: normal;
    opacity: .9;
    color: #000;
}

input {
    display: none;
}

input + label { /* box with rounded corner */
    border: 1px solid #999;
    background: #ffffff;
    padding: 4px 12px;
    border-radius: 4px 4px 0 0;
    position: relative;
    top: 1px;
    border-bottom: 1px solid transparent;
}

input:checked + label {
    background: rgba(120, 120, 120, 0.15);
    border-bottom: 1px solid transparent;
}

input:hover + label {
    background: rgba(117, 116, 116, 0.5);
    /*background-color: rgba(112, 119, 216, 50%);*/
    cursor: pointer;
}

input ~ .tab { /* grey line between tab and contents */
    border-top: 1px solid;
    padding: 12px;
}

/* hide contents */
/* show contents only for selected tab */
#tab1:checked ~ .tab.content1,
#tab2:checked ~ .tab.content2,
#tab3:checked ~ .tab.content3,
#tab4:checked ~ .tab.content4,
#tab5:checked ~ .tab.content5 {
    display: block;
}

.cert-body {
    background-image: url("/images/background_templates/certificate_bg.png");
    background-size: 1145px 795px;
    width: 1185px;
    height: 795px;
    background-repeat: no-repeat;
    padding: 20px;
    text-align: center;
    align-items: center;
    position: relative;
}


.accordion-header {
    border: 1px solid black;
    border-radius: 0;
}

.card {
    border: 1px solid black;
    border-radius: 0;
}

/*.accordion-item {*/
/*  border: 1px solid black;*/
/*  border-radius: 0;*/
/*  padding: 1%;*/
/*}*/
</style>
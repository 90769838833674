<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot class="header">
              <h6 class="modal-title">Are you sure?</h6>
            </slot>
            <button type="button" class="btn-close" data-dismiss="modal" @click="$emit('close')">
            </button>
          </div>
          <slot name="body">
            <div class="modal-body">
              <span>{{ userName }}'s extra attempt on <span style="font-weight: bold;">{{ quizTitle }}</span> will be cancelled.</span>
              <br/>
              <button type="submit" class="btn-primary button" @click="submitCancelRequest" style="float: right;">Cancel
                Retake
              </button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {quizRetakeComposable} from "@/components/QuizRetake/QuizRetakeComposable";
import {onBeforeMount} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {cancelRetake, retakeRequest} from "@/Helpers/ApiCalls/QuizAPICalls";

export default {
  name: "CancelQuizRetakeModal",
  props: {
    courseId: Number,
    quizId: Number,
    userCanvasId: Number,
    userName: String,
    show: Boolean,
    quizTitle: String
  },
  setup(props, context) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];

    onBeforeMount(() => {
    });

    async function submitCancelRequest() {
      await axios.delete(cancelRetake(props.userCanvasId, props.quizId), {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        context.emit("close")
        context.emit("success", result.data)
      })
    }

    return {
      submitCancelRequest
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {
  max-height: 350px;
  max-width: 550px;
  width: 35%;
  height: 45%;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow-y: scroll;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input {
  border: 1px solid black;
}
</style>
<template>
    <h2 class="accordion-header" :id="`quiz-accordion-${questionData.idQuestion}`">
        <button class="accordion-button collapsed" type="button" data-toggle="collapse"
                :data-target="`#question-${questionData.idQuestion}`"
                aria-expanded="false"
                :aria-controls="`question-${questionData.idQuestion}`"
        >
            <div v-html="questionData.questionText"></div>
            <p v-if="questionData.questionType === 'essay_question'" style="font-style: italic">&nbsp;(Essay Question)</p>
        </button>
    </h2>
    <div :id="`question-${questionData.idQuestion}`" class="accordion-collapse collapse"
         :aria-labelledby="`quiz-accordion-${questionData.idQuestion}`">
        <ul style="text-align: left" v-if="questionData.questionType !== 'essay_question'">

            <!--            <strong >-->
            <li v-for="(answer, idx) in questionData.answers" :key="idx"
                :style="`${answer.userAnswered ? 'background-color: #FFFACD;' : ''}`">
                  <span> {{ answer.answerText }}
                    <span :style="`${answer.correct ? 'color: green;' : 'color: red;'}`">
                        {{ answer.correct ? 'Correct' : 'Incorrect' }}
                    </span>
                  </span>
            </li>
            <!--            </strong>-->
        </ul>

        <div v-else v-for="(answer, idx) in questionData.answers" :key="idx" style="padding: 3%">
            <h6>Answer:</h6>
            <div v-html="answer.answerText"></div>
            <div v-if="answer.essayNotes.length > 0" style="font-style: italic; font-size: 12px">
                <hr/>
                <h6>Notes:</h6>
                <div v-for="(note, noteIdx) in answer.essayNotes" :key="noteIdx" >
                    <span style="font-weight: bold; font-style: normal; color: black;">Note By: {{note.notesCreatedBy}} | Note Created: {{note.notesCreatedOn}}</span>
                    <div v-html="note.notesText"></div>
                </div>
            </div>
            <!--            <router-link :to="{name: 'EssayReview', params: {idEssayAnswer: answer.idAnswer, idGrade: idGrade}}">Review This Essay</router-link>-->
        </div>

    </div>
</template>

<script>
export default {
    name: "UserQuestionAnswerDisplay",
    props: {
        questionData: {
            type: Object,
            required: true,
        },
        idGrade: {
            type: Number
        }
    }
}
</script>

<style scoped>

</style>
import {ref} from "vue";

export const quizRetakeComposable = () => {
    const quizRetake = ref({
        courseId: Number,
        userCanvasId: Number,
        attemptCount: Number,
        quizId: Number
    })

    function changeCourseId(idCourse) {
        quizRetake.value.courseId = idCourse;
    }

    function changeUserId(idUser) {
        quizRetake.value.userCanvasId = idUser;
    }

    function changeAttemptCount(count) {
        quizRetake.value.attemptCount = parseInt(count);
    }

    function changeQuizId(idQuiz) {
        quizRetake.value.quizId = idQuiz;
    }

    return {
        quizRetake,
        changeCourseId,
        changeUserId,
        changeAttemptCount,
        changeQuizId
    }
}
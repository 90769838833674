<template>
  <div id="downloadContent" ref="downloadContent">
    <div class="cert-body row" >
      <div>
        <br/><br/>
        <span><i>This is to certify that</i></span>
        <br><br>
        <span><b>{{ studentName }}</b></span><br/><br/>
        <span><i>has completed the certification</i></span> <br/><br/>
        <span>{{ certificationName }}</span> <br/><br/>
        <div class="col-sm-4" style="margin-left: 65px; position: absolute;">
          <span style="font-size: 25px;">Date Completed</span>
          <p ><span style="font-size: 25px; text-decoration: underline;">{{ completionDate }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted, computed, watch} from "vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  name: "CertificationPdfGenerator",
  props: {
    studentName: String,
    completionDate: String,
    certificationName: String,
    download: Boolean
  },
  setup(props, context) {
    const downloadContent = ref(null);

    watch(() => props.download, () => {
      if (props.download === true){
        downloadContent.value.focus()
        saveDoc();
      }

    })

    onMounted(() => {
      downloadContent.value.focus();
    })

    const fileName = computed(() => {
      return `${props.studentName.replace(" ", "_")}_${props.certificationName.replace(" ", "_")}.pdf`;
    })

    function saveDoc() {
      let pdf = new jsPDF({
        orientation: 'l',
        unit: 'px',
        format: 'a4',
        hotfixes: ['px_scaling'],
      });

      html2canvas(downloadContent.value, {
        width: pdf.internal.pageSize.getWidth(), height: pdf.internal.pageSize.getHeight()
      }).then((canvas) => {
        const img = canvas.toDataURL("image/png");
        pdf.addImage(img, "PNG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        pdf.save(fileName.value);
      }).finally(() => {
        context.emit("downloaded");
      })
    }

    return {
      downloadContent,
      saveDoc
    }
  }
}
</script>

<style scoped>
.cert-body {
  background-image: url("/images/background_templates/certificate_bg.png");
  background-size: 1145px 795px;
  width: 1185px;
  height: 795px;
  background-repeat: no-repeat;
  padding: 20px;
  text-align: center;
  align-items: center;
  position: relative;
}

span {
  font-size: 30px;
  font-family: SansSerif,serif;
  color: black;
}
</style>
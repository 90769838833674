<template>
    <Transition name="modal">
        <div v-if="showModal" class="modal-mask" @keydown.esc="$emit('close')">
            <div class="modal-wrapper">
                <div class="modal-container card">
                    <div class="modal-header">
                        <slot name="header">
                            <h3>{{ quizData.quizTitle }}</h3>
                            <!--                            <router-link v-if="idUser !== idViewingUser"-->
                            <!--                                         :to="{name: 'UserQuizView', params: {idQuiz: idQuiz, idUser: idUser, idGrade: idGrade}}">-->
                            <!--                                Review Results-->
                            <!--                            </router-link>-->
                            <div v-if="idViewingUser !== idUser && !activeRetake" >
                                <b-tooltip target="_self" triggers="hover"
                                           title="The save button at the top will save any notes you are actively working on to come back to later. This will not be sent to the student until you hit the submit button at the bottom."
                                           v-if="idViewingUser !== idUser && !activeRetake">
                                    <button class="btn-info" @click="saveButtonEvent" style="border-radius: 5px;">Save &
                                        Close
                                    </button>
                                </b-tooltip>
                            </div>
                            <div>
                                <b-tooltip target="_self" triggers="hover"
                                           title="This will close the quiz info. Any notes for essays you may be reviewing will not be saved.">
                                    <button type="button" class="bi bi-x-lg" data-dismiss="modal" @click="$emit('close')">
                                    </button>
                                </b-tooltip>
                            </div>
                        </slot>
                    </div>

                    <slot name="body">
                        <div class="modal-body">
                            <h4>Student: {{ quizData.studentFirstName }} {{ quizData.studentLastName }} | {{
                                quizData.studentEmail
                                }}</h4>
                            <div class="accordion" style="border-color: black" :id="`quiz-accordion`">
                                <div v-if="idViewingUser === idUser || activeRetake">
                                    <div v-for="(question, index) in quizData.questions" :key="index"
                                         class="accordion-item">
                                        <UserQuestionAnswerDisplay :question-data="question" :id-grade="idGrade"/>
                                    </div>
                                </div>
                                <div v-else>
                                    <UserQuizReview
                                            :quiz-data="quizData"
                                            @close="closeOnSubmit"
                                            :id-quiz="idQuiz"
                                            :id-user="idUser"
                                            :id-grade="idGrade"
                                            :save-swap="saveSwap"
                                            @save="closeOnSave"
                                    />
                                </div>
                            </div>

                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import {ref, onBeforeMount, computed} from "vue";
import axios from "axios";
import {userQuizReport} from "@/Helpers/ApiCalls/QuizAPICalls";
import UserQuestionAnswerDisplay from "@/components/Quizzes/UserQuestionAnswerDisplay.vue";
import UserQuizReview from "@/components/Quizzes/UserQuizReview.vue";
import Swal from "sweetalert2";

export default {
	name: "UserQuizDisplay",
	components: {UserQuizReview, UserQuestionAnswerDisplay},
	props: {
		idUser: {
			type: Number,
			required: true,
		},
		idQuiz: {
			type: Number,
			required: true,
		},
		showModal: {
			type: Boolean,
			required: true,
			default: false,
		},
		idGrade: {
			type: Number,
		},
		idViewingUser: {
			type: Number,
		},
		activeRetake: {
			type: Boolean,
			default: false,
		}
	},
	setup(props, context) {
		const quizData = ref({});
		const saveSwap = ref(false);

		onBeforeMount(() => {
			getQuizData();
		})

		async function getQuizData() {
			await axios.get(userQuizReport(props.idQuiz, props.idUser))
				.then((result) => {
					quizData.value = result.data;
				})
		}

		function closeOnSubmit() {
			context.emit('submittedReview');
			Swal.fire({
				title: "Updated",
				text: "If you denied any essay responses, the student will be asked to retake the quiz.",
				confirmButtonText: "Okay",
			})
		}

		function closeOnSave() {
			context.emit('submittedReview');
			Swal.fire({
				title: "Saved",
				text: "You have saved notes for the essays you were reviewing, you may return to them at any time to finish.",
				confirmButtonText: "Okay"
			})
		}

		function saveButtonEvent() {
			saveSwap.value = !saveSwap.value;
		}

		const encodedUserString = computed(() => {
			let tmp_id = props.idUser;
			return btoa(tmp_id);
		})

		return {
			quizData,
			encodedUserString,
			saveSwap,
			closeOnSubmit,
			closeOnSave,
			saveButtonEvent
		}
	}
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    height: 80%;
}

.modal-container {
    max-width: 900px;
    max-height: 600px;
    width: 90%;
    height: 85%;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
//overflow-y: scroll;
}


.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
    overflow-y: auto;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
<template>
    <div>
        <!--        <h3>{{ quizData.quizTitle }}</h3>-->
        <!--        <h4>Responses for {{ quizData.studentFirstName }} {{ quizData.studentLastName }}</h4>-->
        <!--        <h6>-->
        <ul style="color: black;">
            <li>If you are denying an essay, you must include notes on why it is being denied.

            </li>
            <li>You may include notes for when approving an essay, but it is not required.
            </li>
            <li>A button to submit any notes will appear at the bottom after you have either approved an
                essay or added notes.
            </li>
            <li>The save button at the top will save any notes you are actively working on
                to come back to later.
            </li>
            <li>This will not be sent to the student until you hit the submit button at the
                bottom.
            </li>
        </ul>
        <!--        </h6>-->
    </div>
    <div class="card-body">
        <div v-for="(question, qIdx) in quizData.questions" :key="qIdx"
             style="text-align: left; border: 1px solid black; margin-bottom: 1%; padding: 1%">
            <div v-html="question.questionText"></div>
            <p v-if="question.questionType === 'essay_question'" style="font-style: italic">&nbsp;(Essay Question)</p>
            <div :id="`question-${question.idQuestion}`">
                <ul v-if="question.questionType !== 'essay_question'" style="list-style-type: none">
                    <li v-for="(answer, ansIdx) in question.answers" :key="ansIdx">
                    <span :style="answer.userAnswered ? `background-color: #FFFACD` : ``"> {{ answer.answerText }}
                        <span :style="`${answer.correct ? 'color: green;' : 'color: red;'}`">
                            {{ answer.correct ? 'Correct' : 'Incorrect' }}
                        </span>
                  </span>
                    </li>
                </ul>
                <div v-else @submit.prevent="updateEssayHelper">
                    <div v-for="(answer, ansIdx) in question.answers" :key="ansIdx">
                        <EssayReviewComponent :updating-user-id="authUser.idUser" :id-grade="idGrade"
                                              :essay-data="answer"
                                              :question-text="question.questionText"
                                              @essayUpdate="updateEssayNotesList($event)"
                                              @validateForm="updatedValidatedForms($event, question.idQuestion)"
                        />
                    </div>
                </div>

            </div>
        </div>
        <div v-if="canSubmit" class="col-sm-3 offset-9">
            <button @click="updateEssayHelper" class="button btn-primary">Update Essay</button>
        </div>
    </div>

</template>

<script>
import {computed, ref, watch} from "vue";
import store from "@/store";
import axios from "axios";
import {saveOrUpdateEditingNotesInList, updateEssaysForQuizAndUser} from "@/Helpers/ApiCalls/EssayAPICalls";
import Swal from "sweetalert2";
import EssayReviewComponent from "@/components/Essay/EssayReviewComponent.vue";

export default {
	name: "UserQuizReview",
	components: {EssayReviewComponent},
	props: {
		quizData: {
			type: Object,
		},
		idUser: {
			type: Number,
		},
		idQuiz: {
			type: Number
		},
		idGrade: {
			type: Number
		},
		saveSwap: {
			type: Boolean,
		}
	},
	setup(props, context) {
		const authToken = store.getters['auth/authenticated'];
		const authUser = store.getters['auth/user'];
		const essayNotesList = ref({
			idGrade: parseInt(props.idGrade),
			notesList: []
		});
		const validatedNotesForms = ref([]);

		watch(() => props.saveSwap, () => {
			saveWithoutSubmitting();
		})

		function updateEssayNotesList(event) {
			let note = event;
			let existing = essayNotesList.value.notesList.find(e => e.idEssayAnswer === note.idEssayAnswer);
			if (existing !== null && existing !== undefined) {
				let idx = essayNotesList.value.notesList.indexOf(existing);
				essayNotesList.value.notesList[idx] = note;
			} else {
				essayNotesList.value.notesList.push(note);
			}
		}

		function updatedValidatedForms(event, idQuestion) {
			let val = {question: idQuestion, validated: event};
			let existing = validatedNotesForms.value.find(n => n.question === idQuestion);
			if (existing !== null && existing !== undefined) {
				let idx = validatedNotesForms.value.indexOf(existing);
				validatedNotesForms.value[idx] = val;
			} else {
				validatedNotesForms.value.push(val);
			}
		}

		function updateEssayHelper() {
			updateEssay();
		}

		async function updateEssay() {
			for (let i = 0; i < essayNotesList.value.notesList.length; i++) {
				essayNotesList.value.notesList[i].activelyEditing = false;
			}
			await axios.put(updateEssaysForQuizAndUser(props.idUser, props.idQuiz), essayNotesList.value, {
				headers: {
					"Authorization": `Bearer ${authToken.value}`
				}
			}).then((result) => {
				context.emit('close');
			})
		}

		async function saveWithoutSubmitting() {
			await axios.put(saveOrUpdateEditingNotesInList(), essayNotesList.value, {
				headers: {
					"Authorization": `Bearer ${authToken.value}`
				}
			}).then((result) => {
				context.emit('save');
			})
		}

		const numberOfEssays = computed(() => {
			return props.quizData.questions.filter((q) => q.questionType === 'essay_question').length;
		})

		const canSubmit = computed(() => {
			return numberOfEssays.value > 0
				&& numberOfEssays.value === essayNotesList.value.notesList.length
				&& numberOfEssays.value === validatedCount.value;
		})

		const validatedCount = computed(() => {
			return validatedNotesForms.value.filter(vn => vn.validated === true).length;
		})

		return {
			essayNotesList,
			canSubmit,
			authUser,
			updatedValidatedForms,
			updateEssayHelper,
			updateEssayNotesList,
			saveWithoutSubmitting,
		}
	}
}
</script>

<style scoped>

</style>